const $ = require('jquery');
global.jQuery = $;

require('bootstrap/dist/js/bootstrap.min');
require('./vendor/jquery.lazyload');

import './components/pace';
import '../scss/login.scss';

document.addEventListener('DOMContentLoaded', () => {
    $('.setTooltip').tooltip();
    $('.lazy').lazyload({effect : 'fadeIn'});
});
